<template>
  <el-row :gutter="0">
    <el-col :xs="20" :sm="3" class="mr-2 button_col">
      <el-button type="primary" icon="el-icon-arrow-left" class="mb-3" plain @click="$router.back()">Back
      </el-button>
    </el-col>
    <el-col :xs="24" :sm="20" class="col_mobile">
      <el-card v-loading="loading">
        <h4 class="mt-2 mb-2">Order Details</h4>
        <div class="det">
          <div class="left">
            Order Number
          </div>
          <div class="right">{{ orderDetailInfo.order_no }}</div>
        </div>
        <div class="det">
          <div class="left">
            Order Status
          </div>
          <div class="right">
            {{
            orderDetailInfo.order_status == 0
            ? "Not Paid"
            : orderDetailInfo.order_status == 1
            ? "Paid"
            : orderDetailInfo.order_status == 2
            ? "Free"
            : orderDetailInfo.order_status == 3
            ? "Pending"
            : orderDetailInfo.order_status == 4
            ? "Refunding"
            : orderDetailInfo.order_status == 5
            ? "Refunded"
            : orderDetailInfo.order_status == 6
            ? "Cancel"
            : " "
            }}
          </div>
        </div>
        <div class="det">
          <div class="left">
            Time Zone
          </div>
          <div class="right">{{ orderDetailInfo.time_zone }}</div>
        </div>
        <div class="det">
          <div class="left">
            Order Created Time
          </div>
          <div class="right">{{ orderDetailInfo.created_at }}</div>
        </div>
        <div class="det">
          <div class="left">
            Allow Refund
          </div>
          <div class="right">
            {{ orderDetailInfo.is_refund == 0 ? "No" : "Yes" }}
          </div>
        </div>
        <div class="det">
          <div class="left">
            Order Source
          </div>
          <div class="right">
            {{
            orderDetailInfo.source == 0
            ? "Web"
            : orderDetailInfo.source == 1
            ? "APP"
            : "System"
            }}
            <!-- {{
              orderDetailInfo.source == 0
                ? "Guest"
                : orderDetailInfo.source == 1
                ? "Member"
                : orderDetailInfo.source == 2
                ? "System"
                : "Probationary Member"
            }} -->
          </div>
        </div>

        <h4 class="mt-4 mb-2">Payment Information</h4>
        <div class="det">
          <div class="left">
            Payment Method
          </div>
          <div class="right">{{ paymentList[orderDetailInfo.pay_type] }}</div>
        </div>
        <div class="det">
          <div class="left">
            Time Of Payment
          </div>
          <div class="right">{{ orderDetailInfo.pay_time || "-" }}</div>
        </div>
        <div class="det">
          <div class="left">
            Currency
          </div>
          <div class="right">
            {{ orderDetailInfo.currency == 0 ? "USD" : "EUR" }}
          </div>
        </div>
        <div class="det">
          <div class="left">

            {{$route.query.type==8?"Donation":"Total"}} Amount
          </div>
          <div class="right">
            {{ orderDetailInfo.currency == 0 ? "$" : "€"
            }}{{ orderDetailInfo.order_amount }}
          </div>
        </div>
        <div class="det" v-if="$route.query.type==8">
          <div class="left">
            Commission
          </div>
          <div class="right">
            {{ orderDetailInfo.currency == 0 ? "$" : "€"
            }}{{ orderDetailInfo.fee }}
          </div>
        </div>
        <div class="det">
          <div class="left">
            Payment Amount
          </div>
          <div class="right">
            {{ orderDetailInfo.currency == 0 ? "$" : "€"
            }}{{ orderDetailInfo.pay_amount }}
          </div>
        </div>
        <div class="det" v-if="[3, 4].includes(orderDetailInfo.pay_type)">
          <div class="left">
            Order Number
          </div>
          <div class="right">{{ orderDetailInfo.order_number }}</div>
        </div>
        <div class="det" v-if="[3, 4].includes(orderDetailInfo.pay_type)">
          <div class="left">
            Upload Payment
          </div>
          <div class="right">
            <el-image style="height: 60px" fit="cover" :src="orderDetailInfo.offline_certificate"
              :preview-src-list="srcList">
            </el-image>
          </div>
        </div>
      </el-card>
      <el-card class="mt-2" v-if="$route.query.type==5">
        <div class="d-flex justify-content-between">
          <h4>Membership Information</h4>
        </div>
        <div class="eventInfo row">
          <div class="col-9">
            <div class="det">
              <div class="leftDona">
                Application Type
              </div>
              <div class="right">{{ orderDetailInfo.type==0?"Member":"Probationary Member" }}</div>
            </div>
            <div class="det">
              <div class="leftDona">
                Organization
              </div>
              <div class="right">
                {{ orderDetailInfo.organization }}
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="mt-2" v-else-if="$route.query.type==8">
        <div class="d-flex justify-content-between">
          <h4>Donation Information</h4>
        </div>
        <div class="eventInfo">
          <div>
            <div class="det">
              <div class="leftDona">
                Donation Type
              </div>
              <div class="right">
                {{ {1:'JCI',2:'Foundation',3:'Other'}[orderDetailInfo.donated_type] }}
              </div>
            </div>
            <div class="det">
              <div class="leftDona">
                Donate To
              </div>
              <div class="right">
                {{ orderDetailInfo.donate_to }}
              </div>
            </div>
            <div class="det">
              <div class="leftDona">
                Donor
              </div>
              <div class="right">{{ orderDetailInfo.donor }}</div>
            </div>
            <div class="det">
              <div class="leftDona">
                Donor Email
              </div>
              <div class="right">{{ orderDetailInfo.donor_email }}</div>
            </div>
            <div class="det">
              <div class="leftDona">
                Country
              </div>
              <div class="right">{{ orderDetailInfo.donor_country_id||"-" }}</div>
            </div>
            <div class="det">
              <div class="leftDona">
                Address
              </div>
              <div class="right">{{ orderDetailInfo.donor_address||"-" }}</div>
            </div>
            <div class="det">
              <div class="leftDona">
                Telephone
              </div>
              <div class="right">{{ orderDetailInfo.donor_telephone||"-" }}</div>
            </div>
            <div class="det">
              <div class="leftDona">
                Fax
              </div>
              <div class="right">{{ orderDetailInfo.donor_fax||"-" }}</div>
            </div>
            <div class="det">
              <div class="leftDona">
                Company (if it is a corporate donor)
              </div>
              <div class="right">{{ orderDetailInfo.donor_company||"-" }}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="mt-2" v-else-if="$route.query.type==6">
        <h4>Program Information</h4>
        <div class="eventInfo d-flex justify-content-between">
          <div>
            <el-image :src="orderDetailInfo.image" fit="cover" class="eventImage">
              <div slot="error" class="image-slot">
                <img :src="errorImage" class="errorpng" alt="" />
              </div>
            </el-image>
          </div>
          <div class="ml-3 event_mobile">
            <div class="det">
              <div class="left">
                Program Name
              </div>
              <div class="right">{{ orderDetailInfo.title }}</div>
            </div>
            <div class="det">
              <div class="left">
                Event Name
              </div>
              <div class="right">{{ orderDetailInfo.even_title }}</div>
            </div>
            <div class="det">
              <div class="left">
                Questionnaire Title
              </div>
              <div class="right">{{ orderDetailInfo.questionnaire_title }}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="mt-2" v-else>
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ $route.query.type == 2 ? "Event" : "Meeting" }} Information</h4>
          <el-button type="primary" size="small" @click="getCheckList(orderDetailInfo)">Check Program</el-button>
        </div>
        <div class="eventInfo d-flex justify-content-between">
          <div>
            <el-image :src="orderDetailInfo.image" fit="cover" class="eventImage">
              <div slot="error" class="image-slot">
                <img :src="errorImage" class="errorpng" alt="" />
              </div>
            </el-image>
          </div>
          <div class="ml-3 event_mobile">
            <div class="det">
              <div class="left">
                {{ $route.query.type == 2 ? "Event Name" : "Meeting Name" }}
              </div>
              <div class="right">{{ orderDetailInfo.title }}</div>
            </div>
            <div class="det">
              <div class="left">
                Type
              </div>
              <div class="right">
                {{ orderDetailInfo.order_type == 1 ? "Package" : "Single" }}
              </div>
            </div>
            <div class="det">
              <div class="left">
                Package Name
              </div>
              <div class="right">
                {{
                orderDetailInfo.setmeal_title
                ? orderDetailInfo.setmeal_title
                : "-"
                }}
              </div>
            </div>
            <div class="det">
              <div class="left">
                Is Early Bird
              </div>
              <div class="right">
                {{ orderDetailInfo.is_use_bird_price == 0 ? "No" : "Yes" }}
              </div>
            </div>
            <div class="det">
              <div class="left">
                Unit Price
              </div>
              <div class="right">
                {{ orderDetailInfo.currency == 0 ? "$" : "€"
                }}{{ orderDetailInfo.unit_price }}
              </div>
            </div>
            <div class="det">
              <div class="left">
                Quantity
              </div>
              <div class="right">{{ orderDetailInfo.number }}</div>
            </div>
            <div class="det">
              <div class="left">
                Total Amount
              </div>
              <div class="right">
                {{ orderDetailInfo.currency == 0 ? "$" : "€"
                }}{{ orderDetailInfo.total_price }}
              </div>
            </div>
          </div>
        </div>
        <!-- <el-table :data="tableData" style="width: 100%;">
          <el-table-column
            prop="title"
            align="center"
            show-overflow-tooltip
            label="Event Name"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="order_type"
            show-overflow-tooltip
            align="center"
            label="Type"
            min-width="100"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.order_type == 1 ? "Package" : "Single"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            show-overflow-tooltip
            label="Package Name"
            min-width="150"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.setmeal_title ? scope.row.setmeal_title : "-"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="is_use_bird_price"
            show-overflow-tooltip
            align="center"
            label="Is Early Bird"
            min-width="150"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.is_use_bird_price == 0 ? "No" : "Yes" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_price"
            align="center"
            show-overflow-tooltip
            label="Unit Price"
            min-width="100"
          >
            <template slot-scope="scope">
              <span
                >{{ scope.row.currency == 0 ? "$" : "€"
                }}{{ scope.row.unit_price }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="number"
            align="center"
            show-overflow-tooltip
            label="Quantity"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="total_price"
            label="Total Amount"
            show-overflow-tooltip
            align="center"
            min-width="120"
          >
            <template slot-scope="scope">
              <span
                >{{ scope.row.currency == 0 ? "$" : "€"
                }}{{ scope.row.total_price }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="Action"
            align="center"
            width="70"
            fixed="right"
          >
            <template slot-scope="scope">
              <span
                ><i @click="getCheckList(scope.row)" class="el-icon-view"></i
              ></span>
            </template>
          </el-table-column>
        </el-table> -->
      </el-card>
      <el-card class="mt-2 list_pc" v-if="!['5','6','8'].includes($route.query.type)">
        <div class="d-flex justify-content-between align-items-center">
          <h4>Assigned User List</h4>
          <div v-if="[1, 2].includes(orderDetailInfo.order_status)">
            <el-button v-if="orderDetailInfo.gift_count == orderDetailInfo.number" disabled type="info">The Number Of
              People Is Full</el-button>
            <el-button type="primary" v-else @click="addUser()" plain>
              +Add New</el-button>
          </div>
        </div>
        <p class="mt-2">The assigned user is the person that has access to use the ticket. You can transfer or reassign
          your ticket(s) to someone else here.</p>
        <el-table :data="orderDetailInfo.gift_log" style="width: 100%">
          <el-table-column prop="gift_id" show-overflow-tooltip align="center" width="100" label="ID">
          </el-table-column>
          <el-table-column prop="real_name" show-overflow-tooltip align="center" label="User">
          </el-table-column>
          <el-table-column prop="email" show-overflow-tooltip align="center" label="Email">
          </el-table-column>
          <el-table-column prop="add_time" show-overflow-tooltip align="center" label="Time">
          </el-table-column>
          <el-table-column label="Action" align="center" width="80">
            <template slot-scope="scope">
              <span><i @click="eventDeleteGiftUsers(scope.row.gift_id)" class="el-icon-delete"></i></span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <!-- mobile-----Assigned User List -->
      <el-card class="mt-2 list_mobile" v-if="!['5','6','8'].includes($route.query.type)">
        <div class="d-flex justify-content-between align-items-center">
          <h4>Assigned User List</h4>
          <button type="button" class="btn btn-primary" style="width: 100px"
            @click="$router.push(`/mobileUserList?id=${$route.query.id}&type=${$route.query.type}`)">Check</button>
        </div>
      </el-card>

      <el-card class="mt-2 list_mobile">
        <div class="d-flex justify-content-between align-items-center">
          <h4>Order history list</h4>
          <button type="button" class="btn btn-primary" style="width: 100px"
            @click="$router.push(`/mobileHistoryList?id=${$route.query.id}&type=${$route.query.type}`)">Check</button>
        </div>
      </el-card>

      <el-card class="mt-2 list_pc">
        <h4>Order History List</h4>
        <el-table :data="logList" style="width: 100%">
          <el-table-column prop="operator_type" align="center" width="120" label="Type">
            <template slot-scope="scope">
              <span>{{
                scope.row.operator_type == 1 ? "System" : "Client"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column width="170" show-overflow-tooltip prop="created_at" align="center" label="Time">
          </el-table-column>
          <el-table-column prop="order_status" align="center" show-overflow-tooltip width="130" label="Order status">
            <template slot-scope="scope">
              <span>{{
                scope.row.order_status == 0
                ? "Not Paid"
                : scope.row.order_status == 1
                ? "Paid"
                : scope.row.order_status == 2
                ? "Free"
                : scope.row.order_status == 3
                ? "Pending"
                : scope.row.order_status == 4
                ? "Refunding"
                : scope.row.order_status == 5
                ? "Refunded"
                : scope.row.order_status == 6
                ? "Cancel"
                : "-"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="action_note" align="center" show-overflow-tooltip label="Remark">
          </el-table-column>
        </el-table>
        <div style="text-align: right; margin-top: 30px; margin-bottom: 10px">
          <el-pagination :current-page="page" background layout="prev, pager, next" :total="total" :page-size="pageSize"
            @current-change="handleChangePage" hide-on-single-page></el-pagination>
        </div>
      </el-card>
      <el-card class="mt-2">
        <h4>Operation</h4>
        <div class="pt-4">
          <el-button @click="orderCheck(1)" v-if="[0].includes(orderDetailInfo.order_status)" type="primary">Paid
          </el-button>
          <el-button @click="orderCheck(2)" v-if="[0, 3].includes(orderDetailInfo.order_status)" type="primary">Cancel
          </el-button>
          <el-button @click="orderCheck(3)" v-if="
              [1].includes(orderDetailInfo.order_status) &&
                orderDetailInfo.is_refund == 1 &&
                [2, 3, 4, 5].includes(orderDetailInfo.pay_type)
            " type="primary">Request Refund</el-button>
          <el-button @click="openNotes()" type="primary">Remark</el-button>
        </div>
      </el-card>
    </el-col>
    <el-dialog title="All Program" width="30%" :visible.sync="checkEvent">
      <div style="border-bottom:1px solid #f1f1f1;color:#666; padding:10px 0" v-for="(item, index) of checkList"
        :key="index">
        {{ item.program_title }}
      </div>
    </el-dialog>
    <el-dialog title="Add New" width="40%" :visible.sync="dialogUser">
      <div class="flex">
        <el-select v-model="member" style="width: 100%" filterable placeholder="Please Select"
          :filter-method="filterFriends" clearable popper-class="optionsContent" value-key="user_id"
          @change="chooseMember">
          <el-option v-for="item in userList" :key="item.user_id" :label="item.real_name" :value="item">
            <el-table :data="[item]" style="width: 100%" :show-header="false" tooltip-effect="light">
              <el-table-column prop="date" width="60">
                <template slot-scope="scope">
                  <div class="flex justify-end">
                    <el-avatar icon="el-icon-user-solid" :src="scope.row.avatar" :size="30">
                    </el-avatar>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="real_name" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="email" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="organization_name" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </el-option>
        </el-select>
      </div>
      <div class="vx-row flex items-center" v-if="tagsList.length > 0">
        <div class="vx-col sm:w-12/12 w-full">
          <el-tag v-for="(tag, i) in tagsList" :key="tag.user_id" closable @close="delTag(i)" type="info"
            class="mr-3 mt-3">
            {{ tag.real_name }}
          </el-tag>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="tagsList.length == 0" @click="eventGiftUsers">Submit</el-button>
      </div>
    </el-dialog>
    <el-dialog title="Notes" width="40%" :visible.sync="dialogNotes">
      <el-input type="textarea" :rows="2" placeholder="Please input" v-model="notes">
      </el-input>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="!notes" @click="orderRemark()">Submit</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        errorImage: require('@/assets/images/public/fail.png'),
        tableData: [],
        orderDetailInfo: {},
        logList: [],
        pageSize: 10,
        total: 0,
        page: 1,
        srcList: [],
        paymentList: [
          'Not Sure',
          'Free',
          'PayPal',
          'Offline[personal]',
          'Offline[group]',
          'Credit card payment',
          'System'
        ],
        checkList: [],
        checkEvent: false,

        dialogUser: false,
        userList: [],
        member: '',
        tagsList: [],

        notes: '',
        dialogNotes: false,
        loading: false
      }
    },

    computed: {},

    created() { },
    mounted() {
      this.getOrderDetail()
      this.getOrderLogList()
    },
    methods: {
      delTag(i) {
        this.tagsList.splice(i, 1)
      },
      openNotes() {
        this.notes = ''
        this.dialogNotes = true
      },
      orderRemark() {
        this.$http
          .orderRemark({
            id: this.$route.query.id,
            note: this.notes
          })
          .then(res => {
            if (res.status == 200) {
              this.dialogNotes = false
              this.notes = ''
              this.getOrderLogList()
            }
          })
      },
      addUser() {
        this.member = ''
        this.tagsList = []
        this.dialogUser = true
        this.searchUserList()
      },
      getCheckList(item) {
        let ajaxType = {
          2: 'getEventProgramList',
          4: 'getMeetingProgramList'
        }
        this.$http[ajaxType[this.$route.query.type]]({
          [this.$route.query.type == 2 ? 'event_id' : 'meeting_id']: this.$route.query.type == 2 ? item.event_id : item.meeting_id,
          order_type: item.order_type,
          order_data: item.order_data
        })
          .then(res => {
            if (res.status == 200) {
              this.checkList = res.data
              this.checkEvent = true
            }
          })
      },
      orderCheck(type) {
        let ajaxCancel = {
          2: 'eventOrderCancel',
          4: 'meetingOrderCancel',
          6: 'cancelProgramOrder',
          8: 'donationOrderCancel'
        }
        let ajaxRefund = {
          2: 'eventRefundApply',
          4: 'meetingRefundApply'
        }
        if (type == 1) {
          this.$router.push({
            path: '/PayMoney',
            query: {
              model_type: 2,
              id: this.orderDetailInfo.id,
              model_id: this.orderDetailInfo.event_id,
              package_type: this.orderDetailInfo.package_type
            }
          })
        } else if (type == 2) {
          this.$confirm('Are you sure you want to cancel?', 'Tips', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
            .then(() => {
              this.$http[ajaxCancel[this.$route.query.type]]({ id: this.orderDetailInfo.id })
                .then(res => {
                  if (res.status == 200) {
                    this.getOrderLogList()
                    this.getOrderDetail()
                  }
                })
            })
            .catch(() => { })
        } else if (type == 3) {
          this.$confirm('Are you sure you want to apply for a refund?', 'Tips', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
            .then(() => {
              this.$http[ajaxRefund[this.$route.query.type]]({ id: this.orderDetailInfo.id })
                .then(res => {
                  if (res.status == 200) {
                    this.getOrderLogList()
                    this.getOrderDetail()
                  }
                })
            })
            .catch(() => { })
        }
      },
      searchUserList(val) {
        this.$http
          .searchUserList({
            keyword: val || ''
          })
          .then(res => {
            this.userList = res.data
          })
      },
      chooseMember(item) {
        let idArray = this.tagsList.map(ele => {
          return ele.user_id
        })
        if (
          this.tagsList.length >=
          this.orderDetailInfo.number - this.orderDetailInfo.gift_count
        ) {
          this.$message.warning('The number of donors exceeds!')
          return
        }
        if (idArray.includes(item.user_id)) return
        if (item) {
          this.tagsList.push(item)
          this.member = ''
          this.searchUserList()
        }
      },
      filterFriends(val) {
        clearTimeout(this.timeId)
        this.timeId = setTimeout(() => {
          this.searchUserList(val)
        }, 800)
      },
      eventDeleteGiftUsers(gift_id) {
        let ajaxType = {
          2: 'eventDeleteGiftUsers',
          4: 'meetingDeleteGiftUsers'
        }
        this.$confirm(
          'This operation will delete the record. Are you sure to continue?',
          'Tips',
          {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
          .then(() => {
            this.$http[ajaxType[this.$route.query.type]]({ gift_id }).then(res => {
              if (res.status == 200) {
                this.getOrderLogList()
                this.getOrderDetail()
              }
            })
          })
          .catch(() => { })
      },
      eventGiftUsers() {
        let ajaxType = {
          2: 'eventGiftUsers',
          4: 'meetingGiftUsers'
        }
        let idList = []
        this.tagsList.forEach(item => {
          idList.push(item.user_id)
        })
        console.log(this.$route.query.type);

        this.$http[ajaxType[this.$route.query.type]]({ id: this.$route.query.id, user_id: idList })
          .then(res => {
            if (res.status == 200) {
              this.getOrderDetail()
              this.getOrderLogList()
              this.dialogUser = false
            }
          })
      },
      handleChangePage(num) {
        this.page = num
        this.getOrderLogList()
      },
      getOrderLogList() {
        this.$http
          .getOrderLogList({
            id: this.$route.query.id,
            per_page: this.pageSize,
            page: this.page
          })
          .then(res => {
            if (res.status == 200) {
              this.logList = res.data.data
              this.total = res.data.total
            }
          })
      },
      getOrderDetail() {
        this.loading = true
        let ajaxType = {
          2: 'eventOrderDetail',
          4: 'meetingOrderDetail',
          5: 'vipOrderDetail',
          6: 'programOrderDetail',
          8: 'donationOrderDetail'
        }
        this.tableData = []
        this.srcList = []
        this.$http[ajaxType[this.$route.query.type]]({
          id: this.$route.query.id
        }).then(res => {
          this.loading = false
          if (res.status == 200) {
            this.orderDetailInfo = res.data
            this.tableData.push(res.data)
            this.srcList.push(res.data.offline_certificate)
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  @media (max-width: 600px) {
    ::v-deep .el-dialog {
      width: 90% !important;
    }

    .col_mobile {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .list_pc {
      display: none;
    }

    ::v-deep .el-card__body {
      padding: .625rem;
    }

    .det {
      justify-content: space-between;

      .left,
      .right {
        font-size: 13px;
        padding: 0 !important;
      }

      .left {
        margin-right: .3125rem;
      }

      .right {
        max-width: 12.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    h4 {
      font-size: 1.125rem !important;
    }

    .eventImage {
      width: 5rem !important;
      height: 5rem !important;
    }

    .event_mobile {
      margin-left: .625rem !important;
      flex: 1;

      .right {
        max-width: 7.5rem;
      }
    }
  }

  @media not screen and (max-width: 600px) {
    .button_col.el-col-sm-3 {
      width: 110px !important;
    }
    
    .list_mobile {
      display: none;
    }

    .event_mobile {
      flex: 1;
    }
  }

  .optionsContent .el-select-dropdown__item {
    height: 55px !important;
    padding: 0 !important;
  }

  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
  }

  ::v-deep .el-dialog__body {
    padding: 15px;
  }

  .det {
    display: flex;

    .left {
      color: #333333;
      padding: 5px 0;
    }

    .right {
      flex: 1;
      color: #999999;
      padding: 5px 0;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .eventInfo {
    padding: 5px 0;

    .left,
    .right {
      padding: 0 0 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .eventImage {
      width: 200px;
      height: 200px;
      background: #cccccc;
      display: flex;
      align-items: center;
      justify-content: center;

      .errorpng {
        width: 38px;
        height: 31px;
      }
    }
  }
</style>